var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.head,"items":_vm.files,"items-per-page":10,"loading":_vm.loading},on:{"click:row":_vm.visualize},scopedSlots:_vm._u([{key:"header.checking",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ml-4",attrs:{"value":"check","true-value":"check","false-value":null,"input-value":_vm.selectall},on:{"change":_vm.checkAll}})]}},{key:"item.checking",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ml-4",attrs:{"value":true,"true-value":true,"false-value":false,"input-value":_vm.isChecked(item) ? true : false},on:{"change":function($event){return _vm.checkItem($event, item)},"click":function($event){$event.stopPropagation();}}})]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.filename)+"\n    ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"title":"Supprimer"},on:{"click":function($event){$event.stopPropagation();return _vm.actionItem('delete', item)}}},[_vm._v("\n                mdi-delete\n            ")]),_vm._v(" "),_c('v-icon',{staticClass:"ml-2",attrs:{"title":"Restaurer"},on:{"click":function($event){$event.stopPropagation();return _vm.actionItem('restore', item)}}},[_vm._v("\n                mdi-file-restore\n            ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }