window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.baseUrl = process.env.MIX_BASE_URL
window.urlFront = process.env.MIX_BASE_URL_FRONT
console.log(process.env, ' et ', process)
    /**
     * Echo exposes an expressive API for subscribing to channels and listening
     * for events that are broadcast by Laravel. Echo and event broadcasting
     * allows your team to easily build robust real-time web applications.
     */

import Echo from "laravel-echo"

window.Pusher = require('pusher-js');


// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: false,
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     disableStats: true,
//     authEndpoint: 'http://'+window.location.hostname+'/noah-voyage-api/broadcasting/auth'
// });
//
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true,
    wsHost: 'noah-voyages.com',
    wssHost: 'noah-voyages.com',
    wsPort: 6001,
    wssPort:6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss']
    // authEndpoint: 'http://'+window.location.hostname+'/noah-voyage-api/broadcasting/auth'
});
