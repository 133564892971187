var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.head,"items":_vm.items,"items-per-page":10,"loading":_vm.loading},on:{"click:row":_vm.visualize},scopedSlots:_vm._u([{key:"header.checking",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ml-4",attrs:{"value":"check","true-value":"check","false-value":null,"input-value":_vm.selectinused},on:{"change":_vm.checkAll}})]}},{key:"item.checking",fn:function(ref){
var item = ref.item;
return [(_vm.isInused(item))?_c('v-checkbox',{staticClass:"ml-4",attrs:{"value":true,"true-value":true,"false-value":false,"input-value":_vm.isChecked(item) ? true : false},on:{"change":function($event){return _vm.checkItem($event, item)},"click":function($event){$event.stopPropagation();}}}):_vm._e()]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.filename)+"\n    ")]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatus(item.target),"dark":""}},[_vm._v("\n            "+_vm._s(item.target ? item.target.title : 'Non utilisé')+"\n        ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.isInused(item))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v("\n                mdi-delete\n            ")]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }